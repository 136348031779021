<template>
	<v-sheet color="transparent" class="pa-4">
		<SearchByTags @search="(data) => search = data" :searchItems="goalGroups" @change="(data) => filtered = data"></SearchByTags>
		<p class="text-right">
			<Import collection="goalGroups" defaultFields="name, description"></Import>
		</p>
		<v-card flat color="secondary">
			<v-data-table :headers="headers" :items="items" :search="search">
				<template v-slot:item.button="{ item }">
					<ListButtons :id="item.id" collection="goalGroups" path-name="goal-groups"></ListButtons>
				</template>
			</v-data-table>			
		</v-card>
		<Export :rows="items"></Export>

		<p class="text-right">
			<v-btn color="primary" to="/admin/goal-groups/new">{{mwtr.NewGoalGroupNavItem}}</v-btn>
		</p>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import ListButtons from "@/components/admin/lists/ListButtons"
	import SearchByTags from "@/components/admin/panels/SearchByTags"
	import Import from "@/components/admin/panels/Import"
	import Export from "@/components/admin/panels/Export"

	export default {
		name: "GoalGroups", 
		props: {
			
		},
		data: () => {
			return {
				search: "", 
				filtered: false
			}
		},
		components: {
			ListButtons,
			SearchByTags,
			Import,
			Export
		},
		computed: {
			...Vuex.mapState({
				goalGroups: state => state.goalGroups
			}), 
			headers(){
				return [
					{value: "name", text: "Name"},
					{value: "button", text: " "},
				]
			}, 
			items(){
				if( !this.filtered ){
					return Object.values(this.goalGroups)
				}
				else{
					return this.filtered.map( id => this.goalGroups[id] )
				}
			}
		},
		// methods: {
		// }
		created(){
			this.$store.dispatch("goalGroups/fetchAll")
      }

	}
// </script>"